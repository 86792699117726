import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from '../../store/slices/message'
import CurdTokenervice from '../../services/axios/CurdTokenervice'


export const createVocabulary = createAsyncThunk(
    'vocabulary/insertVocabulary', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, data } = arg
        try {
            const res = await CurdTokenervice.create(token, "vocabulary", data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })


export const getVocabulariesTitle = createAsyncThunk('Vocabulary/getVocabTit', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getCourseTitles(token, "coursestitle", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const getAllVocabularies = createAsyncThunk('vocabulary/getAllvocabulary', async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { token, gredId, subjectId } = arg
    try {
        const res = await CurdTokenervice.getDtatByGrade(token, "getvocabularybygrade", gredId, subjectId);
        thunkAPI.dispatch(setMessage(res.data.message))
        return res.data
    } catch (error) {
        const message = error.message
        thunkAPI.dispatch(setMessage(message))
        return rejectWithValue(error.message)
    }
})

export const updateVocabulary = createAsyncThunk(
    'vocabulary/updtateVocabulary', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, vocabularyId, data } = arg
        try {
            const res = await CurdTokenervice.updateData(token, "vocabulary", vocabularyId, data);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

export const deleteVocabulary = createAsyncThunk(
    'vocabulary/deleteVocabulary', async (arg, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { token, vocabularyId } = arg
        try {
            const res = await CurdTokenervice.delData(token, "vocabulary", vocabularyId);
            return (res.data)
        } catch (error) {
            const message = error.message
            thunkAPI.dispatch(setMessage(message))
            return rejectWithValue(error.message)
        }
    })

const vocabularyByGradeSlice = createSlice({
    name: 'vocabulary',
    initialState: { isloading: false, vocabularies: [], message: '', couresTitles: [] },
    reducers: {
        delAllVocabularies: (state, action) => {
            state.message = ''
            state.vocabularies = []
            state.couresTitles = []
        },
        delMessage: (state, action) => {
            state.message = ''
        }
    },
    extraReducers: {
        // Get vocabulary Title
        [getVocabulariesTitle.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getVocabulariesTitle.fulfilled]: (state, action) => {
            state.isloading = false;
            state.couresTitles = action.payload;
            state.message = ''
        },
        [getVocabulariesTitle.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        // Get All Vocabularies
        [getAllVocabularies.pending]: (state, action) => {
            state.isloading = true
            state.message = ''
        },
        [getAllVocabularies.fulfilled]: (state, action) => {
            state.isloading = false;
            state.vocabularies = action.payload;
            state.message = ''
        },
        [getAllVocabularies.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Insert Vocabulary
        [createVocabulary.pending]: (state, action) => {
            state.isloading = true
        },
        [createVocabulary.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
            state.vocabularies = state.vocabularies[0].vocabularies.push(action.payload.data)
        },
        [createVocabulary.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Update Vocabulary
        [updateVocabulary.pending]: (state, action) => {
            state.isloading = true
        },
        [updateVocabulary.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message

        },
        [updateVocabulary.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        },

        //Delete Vocabulary
        [deleteVocabulary.pending]: (state, action) => {
            state.isloading = true
        },
        [deleteVocabulary.fulfilled]: (state, action) => {
            state.isloading = false;
            state.message = action.payload.message
           // state.vocabularies = state.vocabularies[0].vocabularies.filter(el => el.vocabulary_Id != action.payload.vocabulary_Id)
        },
        [deleteVocabulary.rejected]: (state, action) => {
            state.isloading = false
            state.message = action.payload
        }, 

    }
})


export const { delAllVocabularies, delMessage } = vocabularyByGradeSlice.actions
export default vocabularyByGradeSlice.reducer